// -- Setup --------------------------------------------------------------------
    import React from 'react';
// -----------------------------------------------------------------------------

// -- Components ---------------------------------------------------------------
// -----------------------------------------------------------------------------

class Logo extends React.Component{
    render(){
        return(
            <h1 className="logo">The Shop</h1>
        )
    }
}

export default Logo;
